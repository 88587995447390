import React from 'react';
export const Contact00DataSource = {
  wrapper: { className: 'home-page-wrapper content10-wrapper' },
  Content: {
    className: 'icon-wrapper',
    children: {
      icon: {
        className: 'icon',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/zIUVomgdcKEKcnnQdOzw.svg',
        name: '主要图标',
      },
      iconShadow: {
        className: 'icon-shadow',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/WIePwurYppfVvDNASZRN.svg',
        name: '图标影阴',
      },
      url: { children: 'https://www.google.com.sg/maps/place/Gates+Human+Resources+Pte+Ltd/@1.3097781,103.8530846,15z/data=!4m12!1m6!3m5!1s0x31da19c984b3d2ef:0x35ef78b4566123d9!2sGates+Human+Resources+Pte+Ltd!8m2!3d1.3097781!4d103.8618394!3m4!1s0x31da19c984b3d2ef:0x35ef78b4566123d9!8m2!3d1.3097781!4d103.8618394', name: '跳转地址' },
      title: { children: 'Gates International Solutions Pte Ltd', name: '弹框标题' },
      content: {
        children: '52 Horne Road, #02-01 Singapore 209071',
        name: 'gates',
      },
    },
  },
};
