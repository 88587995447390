import React from 'react';
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>Talent</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <h3>
                Our relevance in this industry depends on our ability to help
                our Client’s business stay ahead of<br />their game. We are
                ready to respond to your specific business and human resource
                needs. We<br />first seek to understand your business objectives
                and desired outcomes. Next, we formulate our<br />internal
                strategies to search candidates from our vast network of
                database to shortlist the best<br />matched resource.<br />Our
                recruitment network covers many countries in Asia – Singapore,
                Malaysia, Indonesia,<br />Thailand, Vietnam, Cambodia,
                Philippines, India, Bangladesh, Japan, South Korea, Taiwan, Hong<br />Kong,
                China and Australia-New Zealand.
              </h3>
            </span>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: '',
        className: 'title-content',
      },
    ],
  },
};
