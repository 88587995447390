import video2 from '../../../assets/Germagic2.mp4'
import vidImage2 from '../../../assets/germagic2.png'
export const Content40DataSource = {
  wrapper: { className: 'home-page-wrapper content4-wrapper' },
  page: { className: 'home-page content4' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: 'Germagic Self Disinfecting Anti-microbial coat',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content content4-title-content',
        children: 'The essence of GERMAGIC™ germicidal technology is using encapsulation technology for controlled- release of active antimicrobials on coated surfaces.Germagic long lasting anti-microbial coating is Alcohol Free, Non - Toxic and Environment Friendly.',
      },
    ],
  },
  video: {
    className: 'content4-video',
    children: {
      video: `${video2}`,
      image: `${vidImage2}`,
    },
  },
};
