/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';
import Nav3 from '../../Nav3';
import Contact0 from './Contact0';
import Point from './Point';
import { Contact00DataSource } from './data.source';
import { Row, Col } from 'antd'
import './less/antMotionStyle.less';
import { Nav32DataSource, Footer00DataSource } from '../../data.source'
import { Card } from 'antd'
import Footer from '../../Footer0';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    const children = [
      <Nav3
        id="Nav3_2"
        key="Nav3_2"
        dataSource={Nav32DataSource}
        isMobile={this.state.isMobile}
      />,
      <Contact0
        id="Contact0_0"
        key="Contact0_0"
        dataSource={Contact00DataSource}
        isMobile={this.state.isMobile}
      />, 
      <Footer
        id="Footer0_0"
        key="Footer0_0"
        dataSource={Footer00DataSource}
        isMobile={this.state.isMobile}
      />,
      // 导航和页尾不进入锚点区，如果需要，自行添加;
      <Point key="list" data={['Contact0_0']} />,
    ];
    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <div
              className="templates-wrapper"
              ref={(d) => {
                this.dom = d;
              }}
            >
              {/* 如果不是 dva 2.0 替换成 {children} start */}
              {this.state.show && children}
              {/* 如果不是 dva 2.0 替换成 {children} end */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className="site-card-border-less-wrapper">
              <Card title="Contact" 
              bordered={false} 
              style={{ 
                width: 300,
                textAlign: 'left'         
                }}>
                <p>contact@gateshr.com</p>
                <p>+65 6747 9066</p>
               
              </Card>
            </div>,
        </Col>
          <Col span={12}>
            <div className="site-card-border-less-wrapper">
              <Card title="Address" 
              bordered={false} 
              style={{ 
                width: 300,
                textAlign: 'left'
                
                }}>
                <p>52 Horne Road, #02-01 </p>
                <p>Singapore 209071</p>
              
              </Card>
            </div>
        </Col>
        </Row>

      </React.Fragment>

    );
  }
}
