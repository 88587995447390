import React from 'react';
import disinfectant from '../../assets/disinfect.png';
import gym from '../../assets/gym.jpeg';
import people from '../../assets/people.jpg'
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    // children: 'https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png',
    children: disinfectant,
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <span>
          <span>
            <span>
              <p>Pure Air+ and Clean Surfaces+</p>
            </span>
          </span>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <p>
                  With over 90% of the world's population exposed to unsafe levels of air polution and pathogens, it is our commitment to help people to breath clean and pure air for healthy living. Our Pire Air+ solution is based on GERMAGIC breakthrough patented technology in antimicrobial coating for HEPA filters by removing pollutants, killing viruses, bacteria and fungi in the living environment&nbsp;
                </p>
                {/* <h3>
                  Germagic Patented Air Disinfection Filters
                </h3>
                <p>
                  Our Pure Air + solution is based on GERMAGIC™ breakthrough patented-technology in
                  antimicrobial coating for HEPA filters by removing pollutants, killing viruses, bacteria and fungi in
                  the living environment.
                </p> */}
              </span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: gym,
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <p>
          Tested and Certified
        </p>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <span>
          <p>
            <ul>
              <li>Kills 99.99% of harmful viruses, bacteria and spores</li>
              <li>SARS-CoV-2 (Covid-19)</li>
              <li>H1N1, H7N9, Influenza A &amp; </li>
              <li> EV71 (HFMD)</li>
              <li>E-coli bacteria and over 100 harmful germs</li>
            </ul>
          </p>
        </span>
      </span>
    ),
  },
};
export const Feature11DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: people,
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>Giving you a productive workforce </p>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <span>
          <p>
            Confident interactions with co-workers confidently within a safe and healthy workplace gives rise with enhanced productivity.{' '}
          </p>
        </span>
      </span>
    ),
  },
};
