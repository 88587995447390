import video1 from '../../../assets/Germagic1.mp4'
import vidImage1 from '../../../assets/germagic1.png'
export const Content40DataSource = {
  wrapper: { className: 'home-page-wrapper content4-wrapper' },
  page: { className: 'home-page content4' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: 'Germagic Patented Air Disinfection Filters ',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content content4-title-content',
        children: 'Our Pure Air + solution is based on GERMAGIC breakthrough patented-technology in antimicrobial coating for HEPA filters by removing pollutants, killing viruses, bacteria and fungi in the living environment.',
      },
    ],
  },
  video: {
    className: 'content4-video',
    children: {
      video: `${video1}`,
      image: `${vidImage1}`,
    },
  },
};
