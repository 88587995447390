/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';
import { Typography, Layout, Space } from 'antd';

import Nav3 from '../../Nav3';
import Banner1 from '../../Banner1';
import Footer0 from '../../Footer0';
import Point from '../../Point';
import {
    Nav32DataSource,
    Banner10DataSource,
    Feature30DataSource,
    Footer00DataSource,
} from '../../data.source';
import '../../less/antMotionStyle.less';
import './About.scss'

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};
const { Title, Paragraph, Text, Link } = Typography;
const { Content } = Layout;

export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port, // 如果不是 dva 2.0 请删除
        };
    }

    componentDidMount() {
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
        /* 如果不是 dva 2.0 请删除 start */
        if (location.port) {
            // 样式 build 时间在 200-300ms 之间;
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
        /* 如果不是 dva 2.0 请删除 end */
    }

    render() {
        const children = [
            <Nav3
                id="Nav3_2"
                key="Nav3_2"
                dataSource={Nav32DataSource}
                isMobile={this.state.isMobile}
            />,
            <Banner1
                id="Banner1_0"
                key="Banner1_0"
                dataSource={Banner10DataSource}
                isMobile={this.state.isMobile}
            />,


            <Layout>
                <Content
                    className='about'>
                    <Typography>
                        <Title>About Us</Title>
                        <Text>
                            <Paragraph>Headquartered in Singapore since 2006. Gates is Singapore’s leading recruitment firm,
                            specializing in a broad spectrum of industries. Our consulting team takes pride in creating long
                            term positive outcomes for our clients and candidates. We provide services to many of the
                            world’s leading companies ranging from Hospitality, MICE, Retail, Advertising, Energy,
                                Infrastructure to Financial Institutions.</Paragraph>
                            <Paragraph>Globalisation has altered the traditional boundaries of talent pool limitations. The Global
                            workforce today embraces diversity, multiple languages, IT skills and critical thinking abilities.
                            With this rapid transformation in motion, we help our clients keep pulse of the talent dynamics
                                in order to execute accurate hiring strategies to stay ahead of their competitors.</Paragraph>
                            <Paragraph>At Gates, we believe in creating a Safe, Clean, Healthy and Sustainable Environment. As a
                            Germagic TM Specialist, we deliver Anti-Epidemic Solutions that improves the Space (Air, Water
                            and Surfaces) that surrounds us. With cutting-edge technological solutions in environmental
                            health, we aim to enhance the quality of the environment, improving health and well-being of
                                people and communities.</Paragraph>



                        </Text>

                    </Typography>

                </Content></Layout>,

            <Footer0
                id="Footer0_0"
                key="Footer0_0"
                dataSource={Footer00DataSource}
                isMobile={this.state.isMobile}
            />, // 导航和页尾不进入锚点区，如果需要，自行添加;
            <Point
                key="list"
                data={['Nav3_2', 'Banner1_0', 'Feature3_0']}
            />,
        ];
        return (
            <div
                className="templates-wrapper"
                ref={(d) => {
                    this.dom = d;
                }}
            >
                {/* 如果不是 dva 2.0 替换成 {children} start */}
                {this.state.show && children}
                {/* 如果不是 dva 2.0 替换成 {children} end */}
            </div>
        );
    }
}
