import React from 'react';
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>Anti Epidermic Solution</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <h3>
                History has taught us that pandemic outbreaks will occur ever so suddenly without warning. Spanish
                Flu, SARS, Ebola, Bird Flu, Swine Flu, Covid-19…etc. With meticulous preparation and rapid response,
                we can prevent most outbreaks from getting out of control, and limit the impact of those that
                spread internationally.
                Investing early in prevention controls and measures is often much cheaper than dealing with a full-
                blown outbreak, with dire consequences to human lives and financial damage.
              </h3>
            </span>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: '',
        className: 'title-content',
      },
    ],
  },
};
