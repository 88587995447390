import React from 'react';
import booostVoiceImg from '../../assets/boostvoiceImg.png'
import thermometer from '../../assets/thermometer.png'

export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: `${thermometer}`,
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <span>
          <span>
            <span>
              <p>Facial Biometric Thermometer System</p>
            </span>
          </span>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <p>
                  <ul>
                    <li>Contactless temperature and face mask detection</li>
                    <li>Integration with gantry systems</li>
                    <li>Facial recognition with 30K face database</li>
                    <li>Check In/ Check Out with date and time records</li>
                    <li>User-friendly backend cloud system</li>
                  </ul>



                </p>
              </span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: 'https://zos.alipayobjects.com/rmsportal/tvQTfCupGUFKSfQ.png',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <p>HR Attendance and Payroll</p>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <span>
          <p>
            This simplified Payroll, processing with a formula builder, supports management of Attendance, leave, loan, claims and etc. It Auto calculates CPF/ Funds and able to print Itemized pay slip and supports monthly CPF file generation, Bank file generation and year-end IRAS auto inclusion submission.{' '}
          </p>
        </span>
      </span>
    ),
  },
};
export const Feature11DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: `${booostVoiceImg}`,
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>Boost Voice</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <span>
          <p>
            Enhance your staff’s comprehension, speaking and vocabulary skills in Mandarin and/or English with Boost Voice. Course content is department specific and has been developed by hoteliers. It provides a highly interactive continuous learning experience, teaching employees to speak a language that is most relevant to their workplace.
          </p>
        </span>
      </span>
    ),
  },
};
