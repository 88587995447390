
import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"
import './App.less';

import Home from './index1'
import About from './Pages/About/About'
import Talent from './Pages/Talent/Talent'
import HRTech from './Pages/HR-Tech/HRTech'
import Aes from './Pages/Aes/Aes'
import Manpower from './Pages/Manpower/Manpower'
import Contact from './Pages/Contact/Contact'







const App = () => {

  return (
   
    <Router>
      

      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Switch>
        <Route path="/talent">
          <Talent />
        </Route>
        <Route path="/manpower">
          <Manpower />
        </Route>
        <Route path="/hrtech">
          <HRTech />
        </Route>
        <Route path="/aes">
          <Aes />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
  
    </Router>

  )

}

export default App;