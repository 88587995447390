import React from 'react'
import { Row, Col, Image, Typography, Carousel } from 'antd'
import './banner.scss'
import pic from '../assets/toddlers.jpeg'
import '../less/antMotionStyle.less';
import Content from './Content13'
import { Content130DataSource } from './data.source'
import { enquireScreen } from 'enquire-js';
const { Paragraph, Text,Title } = Typography


export default class Banner extends React.Component {

    render() {
    
        return (
            
            <Content
                id="Content13_0"
                key="Content13_0"
                dataSource={Content130DataSource}
                // isMobile={this.state.isMobile}
            />

        )
    }




}